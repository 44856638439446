// @flow
/* eslint-disable react/no-danger */

import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Hidden } from '@material-ui/core';
import { useLocation } from '@reach/router';

import '../components/Layout/Layout.scss';
import styles from './merchant-campaign.module.scss';

const PATHS_WITHOUT_CTA = ['multiplemerchants_2022_november'];

const CustomMerchantCampaignTemplate = ({
  pageContext,
}: {
  pageContext: Object,
}) => {
  const {
    campaignName,
    bannerImage,
    bannerImageMobile,
    campaignSubsections,
    shouldShowPaidySection,
    paidyFlow,
    shouldShowPromotionalSection,
    // promotionalImage1,
    // promotionalImage2,
    // promotionalContent,
    primaryButtonText,
    primaryButtonUrl,
    paidySectionTitle,
    // campaignSectionTitle,
  } = pageContext;

  const location = useLocation();
  const hideCTA = PATHS_WITHOUT_CTA.some((path) =>
    location.pathname.includes(path)
  );

  return (
    <div className={styles.container}>
      <header className={styles.headerContainer}>
        <div className={cx(styles.titleImg, styles.pc)}>
          <img
            alt={campaignName}
            src={bannerImage}
            height="auto"
            // width="100%"
            loading="lazy"
          />
        </div>
        <div className={cx(styles.titleImg, styles.sp)}>
          <img
            alt={campaignName}
            src={bannerImageMobile}
            height="auto"
            width="100%"
            loading="lazy"
          />
        </div>
      </header>
      {!hideCTA && (
        <section className={styles.ctaWrapper}>
          <OutboundLink
            className="btn primary"
            target="_blank"
            rel="noopener noreferrer"
            href={primaryButtonUrl}
          >
            {primaryButtonText}
          </OutboundLink>
        </section>
      )}
      <section className={styles.campaignSection}>
        {/* <div className={styles.paidySectionTitle}>
          {renderFirstTitle(campaignSectionTitle)}
        </div> */}
        {campaignSubsections.map((subsection) => {
          const [title, content] = subsection;

          return (
            <div key={title} className={styles.campaignSubSection}>
              <div className={styles.title}>{title}</div>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          );
        })}
        <div></div>
      </section>
      {!hideCTA && (
        <section className={styles.ctaWrapper}>
          <OutboundLink
            className="btn primary"
            target="_blank"
            rel="noopener noreferrer"
            href={primaryButtonUrl}
          >
            {primaryButtonText}
          </OutboundLink>
        </section>
      )}
      {shouldShowPaidySection && (
        <section className={styles.paidySection}>
          {/* {renderFirstTitle(paidySectionTitle)} */}
          <h2 className={styles.sectionTitle}>{paidySectionTitle}</h2>
          <div className={styles.flowItems}>
            {paidyFlow.map((flow) => {
              const [icon, title, content] = flow;

              if (!icon && !title && !content) {
                return null;
              }

              return (
                <div key={title} className={styles.flowItemWrapper}>
                  <figure className={styles.flowItem}>
                    <div className={styles.imageWrapper}>
                      <img
                        src={icon}
                        alt="icon"
                        width="184"
                        height={184}
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.textWrapper}>
                      <figcaption dangerouslySetInnerHTML={{ __html: title }} />
                      <div
                        className={styles.flowItemContent}
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    </div>
                  </figure>
                </div>
              );
            })}
          </div>
        </section>
      )}
      {shouldShowPromotionalSection && (
        <div className={styles.promotionSection}>
          <Hidden smDown>
            <img
              src={require('../images/merchant-campaign-promotional.png')}
              alt="promotional"
              width={1125}
              height="auto"
              className={styles.promotionalImg}
              useMap="#promotion-map"
            />
            <map name="promotion-map">
              <area
                target="_blank"
                alt="ios-badge"
                title="ios-badge"
                href="https://apps.apple.com/jp/app/paidy/id1220373112"
                coords="671,323,834,377"
                shape="rect"
              />
              <area
                target="_blank"
                alt="android-badge"
                title="android-badge"
                href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;hl=ja"
                coords="846,326,1003,375"
                shape="rect"
              />
            </map>
          </Hidden>
          <Hidden mdUp>
            <img
              src={require('../images/merchant-campaign-promotional-sp.png')}
              alt="promotional"
              width={375}
              height="auto"
              className={styles.promotionalImg}
              useMap="#promotion-map-sp"
            />
            <map name="promotion-map-sp">
              <area
                target="_blank"
                alt="ios-badge"
                title="ios-badge"
                href="https://apps.apple.com/jp/app/paidy/id1220373112"
                coords="17,609,182,660"
                shape="rect"
              />
              <area
                target="_blank"
                alt="android-badge"
                title="android-badge"
                href="https://play.google.com/store/apps/details?id=com.paidy.paidy&amp;amp;hl=ja"
                coords="190,610,355,656"
                shape="rect"
              />
            </map>
          </Hidden>
        </div>
      )}
      {!hideCTA && (
        <section className={cx(styles.ctaWrapper, styles.pc)}>
          <OutboundLink
            className="btn primary"
            target="_blank"
            rel="noopener noreferrer"
            href={primaryButtonUrl}
          >
            {primaryButtonText}
          </OutboundLink>
        </section>
      )}
      <footer className={styles.footer}>
        <p className={styles.footerText}>
          ペイディについて、わからないこと、不安なことは
          <OutboundLink
            className={styles.paidy}
            href="https://cs.paidy.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちらから
          </OutboundLink>
          お問い合わせください。
        </p>
        <OutboundLink
          className={styles.paidy}
          href="https://paidy.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          paidy.com
        </OutboundLink>
        <span className={styles.copyright}>© Paidy Inc.</span>
        <OutboundLink
          className={styles.badge}
          href="https://twitter.com/paidyjp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require('../images/icon-x-white.svg')}
            alt="Paidy | Twitter"
            width="30"
            loading="lazy"
          />
        </OutboundLink>
      </footer>
    </div>
  );
};

export default CustomMerchantCampaignTemplate;
